import { render, staticRenderFns } from "./CardPlanLoading.vue?vue&type=template&id=55ad2f32&scoped=true&"
import script from "./CardPlanLoading.vue?vue&type=script&lang=ts&"
export * from "./CardPlanLoading.vue?vue&type=script&lang=ts&"
import style0 from "./CardPlanLoading.scss?vue&type=style&index=0&id=55ad2f32&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55ad2f32",
  null
  
)

export default component.exports