
















import { Component, Vue, Prop } from 'vue-property-decorator';

import ExLoading from '@/globalComponents/ExLoading/index.vue';

@Component({
  components: {
    ExLoading,
  },
})
export default class CardPlanLoading extends Vue {
  @Prop({ default: 3 }) quantityLoading !: number;

}
