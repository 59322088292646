







































import {
  Component, Vue, Watch,
} from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import Footer from '@/components/Footer/Footer.vue';
import PlanTypes from './components/PlanTypes/index.vue';
import Banner from './components/Banner/Banner.vue';
import ContainerPlans from './components/ContainerPlans/ContainerPlans.vue';
import ModalDescriptionsPlan from './components/ModalDescriptionsPlan/ModalDescriptionsPlan.vue';

import PlansService from '@/services/Plans/PlansService';

import { Plan } from './interfaces/IShopping';
import { ListTypes } from './components/PlanTypes/interface';

import { PLAN_TYPE } from './constants';

@Component({
  components: {
    PageHeader,
    Footer,
    Banner,
    PlanTypes,
    ContainerPlans,
    ModalDescriptionsPlan,
  },
})
export default class Shopping extends Vue {
  private isLoadingPlans = true;

  private plans: Array<Plan> = [];
  private listTypes: Array<ListTypes> = [];

  private PlansService = new PlansService();

  created() {
    this.getPlans();
  }

  mounted() {
    this.setBreadCrumbs();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get planIdSelected() {
    return this.$store.getters?.activeDescriptiveModal?.id;
  }

  get planTitleSelected() {
    return this.$store.getters?.activeDescriptiveModal?.title;
  }

  @Watch('plans', {
    immediate: true,
    deep: true,
  })
  setListTypes() {
    const list = new Set<number>();

    this.plans.forEach((plan) => {
      list.add(plan.planIntervalCount);
    });

    this.listTypes = Array.from(list)
      .sort((valueFirst: number, valueSecond: number) => valueSecond - valueFirst)
      .map((intervalCount: number) => ({
        name: PLAN_TYPE[intervalCount],
        IntervalCount: intervalCount,
      }));
  }

  async getPlans() {
    try {
      this.isLoadingPlans = true;

      const response = await this.PlansService.getPlans({});

      if (response && response?.plans) {
        this.plans = response.plans;
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os planos.',
        status: 'error',
      });

      console.error(error);
    } finally {
      this.isLoadingPlans = false;
    }
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: 'Loja', to: null },
    ]);
  }
}
